
import useAppSelector from './useAppSelector'
import { TypedUseSelectorHook } from 'react-redux'
import { UIState } from '../slices/ui/types'

/**
 * A hook to access the UI state. This hook takes a UI selector function as an
 * argument.
 */
const useUISelector: TypedUseSelectorHook<UIState> =
  (selector, options) =>
    useAppSelector(state => selector(state.ui), options)

export default useUISelector
